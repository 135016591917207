import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../api/account';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import Copyright from '../components/Copyright';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { fetchAvailableLanguages } from '../api/language';
import Flag from '../components/Flag';

export default function SignUpPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [languageToStudy, setLanguageToStudy] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('nl');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const handleApiError = useApiErrorHandler();

  const getAvailableLanguages = useCallback(async () => {
    try {
      const response = await fetchAvailableLanguages();
      console.log(response.data)
      setAvailableLanguages(response.data)
    } catch (error) {
      setError(handleApiError(error, [t('signUp.error.fetchLanguages')]));
    }
  }, [handleApiError]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  const handleLanguageToStudyChange = (event) => {
    setLanguageToStudy(event.target.value);
  };

  const handleNativeLanguageChange = (event) => {
    setNativeLanguage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('firstName') || !data.get('lastName') || !data.get('email') || !data.get('password') || !data.get('passwordConfirm')) {
      setError([t('signUp.error.allFieldsAreRequired')]);
      return;
    }

    if (data.get('password') !== data.get('passwordConfirm')) {
      setError([t('common.error.passwordsDoNotMatch')]);
      return;
    }

    if (nativeLanguage === languageToStudy) {
      setError([t('signUp.error.languageToStudyEqualToNativeLanguage')]);
      return;
    }

    console.log({
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
      nativeLanguage: nativeLanguage,
      languageToLearn: languageToStudy
    });
    try {
      const response = await registerUser({
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        password: data.get('password'),
        nativeLanguage: nativeLanguage,
        languageToStudy: languageToStudy
      });
      if (response.status === 200) {
        navigate(t('routes.confirmEmail'));
      }
    } catch (error) {
      setError(handleApiError(error, [t('signUp.error.general')]));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('signUp.title')}
        </Typography>
        {error.map((err, index) => (
          <Alert key={index} severity="error" sx={{ mt: 1, mb: 1 }}>{err}</Alert>
        ))}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label={t('common.firstName')}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label={t('common.lastName')}
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t('common.email')}
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label={t('common.password')}
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="passwordConfirm"
                label={t('signUp.confirmPassword')}
                type="password"
                id="passwordConfirm"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="nativeLanguage-select">{t('common.nativeLanguage')}</InputLabel>
                <Select
                  labelId="nativeLanguage-select"
                  id="nativeLanguage-select"
                  value={nativeLanguage}
                  label={t('common.nativeLanguage')}
                  onChange={handleNativeLanguageChange}
                >
                  {/* Rendering available languages */}
                  {availableLanguages.length > 0 ? (
                    availableLanguages.map(language => (
                      <MenuItem key={language.id} value={language.code}>
                        <Flag code={language.code} name={language.name} />
                        {language.name}
                      </MenuItem>
                    ))
                  ) : (
                    // Rendering default language when availableLanguages is not populated yet
                    <MenuItem value="nl">
                      <Flag code={nativeLanguage} name={'Nederlands'} />
                      {'Nederlands'}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="languageToStudy-select">{t('signUp.languageToStudy')}</InputLabel>
                <Select
                  labelId="languageToStudy-select"
                  id="languageToStudy-select"
                  value={languageToStudy}
                  label={t('signUp.languageToStudy')}
                  onChange={handleLanguageToStudyChange}
                >
                  {availableLanguages
                    ?.filter(language => language.code !== 'prs') // Filter out Dari (prs), we dont support speech for that 
                    .map(language => (
                      <MenuItem key={language.id} value={language.code}>
                        <Flag code={language.code} name={language.name} />
                        {language.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('signUp.signUpButton')}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href={t('routes.login')} variant="body2">
                {t('signUp.alreadyHaveAccount')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}